<template>
  <div v-if="$store.getters.ready">
    <Header nav="project" :group="$route.params.group" :project="$route.params.project" active="overview" />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row page-title border-0 clearfix">
            <div class="page-title-left">
              <h5 class="page-title-heading"><div class="breadcrumbs"><router-link :to="`/${project.group.path}`" class="no-link">{{ project.group.name }}</router-link> &bull; <router-link :to="`/${project.group.path}/${project.path}`" class="no-link">{{ project.name }}</router-link></div> Overview</h5>
            </div>
            <div class="page-title-right" v-if="$auth.profile.site_admin">
              <Button :to="`/${project.group.path}/${project.path}/issues/new`" type="primary" class="mr-md-2 d-none d-md-inline-flex" icon="fa-pencil">New Issue</Button>
              <Button type="default" icon="fa-plus" @click="editBoard">Add Board</Button>
            </div>
            <div class="page-title-right" v-else>
              <Button :to="`/${project.group.path}/${project.path}/issues/new`" type="primary" icon="fa-pencil">New Issue</Button>
            </div>
          </div>

          <div class="widget-list">

            <div class="row issuelist">
              <div v-for="board in boards" :key="board.id" class="mb-3" :class="project.boards_display_vertical ? 'col-lg-12' : 'col-lg'">
                <div class="widget-holder" :style="`border-top: 3px #${board.color} solid;`">
                  <div class="widget-bg">
                    <div class="widget-body">
                      <div class="row issuelist">
                        <div class="col-11"><h5 class="box-title mb-0">{{ board.name | stripLabelScope }}</h5></div>
                        <div class="col-1 text-right" v-if="$auth.profile.site_admin">
                          <b-dropdown toggle-class="p-0" variant="link" menu-class="dropdown-card w-dropdown-card-small mt-2" right>
                            <template v-slot:button-content><i class="fal fa-lg fa-ellipsis-v text-muted"></i></template>
                            <div class="card">
                              <header class="card-header d-flex justify-content-between">
                                <i class="fal fa-cog text-primary" aria-hidden="true"></i>
                                <span class="heading-font-family flex-1 text-center fw-400">Settings</span>
                              </header>
                              <div class="card-body list-unstyled dropdown-list">
                                <Button type="dropdown-item" @click="editBoard(board.id)">Edit</Button>
                                <div class="dropdown-divider"></div>
                                <Button type="dropdown-item" class="text-danger" icon="fa-times" @click="deleteBoard(board.id)">Delete</Button>
                              </div>
                            </div>
                          </b-dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <router-link v-for="issue in issues[board.id]" :key="issue.id" :to="`/${project.group.path}/${project.path}/issues/${issue.number}`">
                  <div class="widget-holder">
                    <div class="widget-bg"  :class="issue.state === 'closed' && 'opacity-06'">
                      <div class="widget-body">
                        <div v-if="project.boards_display_vertical" class="row">
                          <div class="col-12 d-sm-none">
                            <label-badge v-for="label in issue.labels" :key="label.id" :color="label.color" :text="label.text_color" :label="label.name" class="mr-1 mb-1" />
                          </div>
                          <div class="col-sm-8 col-md-9 col-lg-10">
                            <h5 class="box-title mb-0">{{ issue.title }}</h5>
                            <div class="d-none d-sm-block"><span class="text-muted mr-2">#{{ issue.number }} opened {{ $datefns.fromNow(issue.created_at) }} by {{ issue.created_by.username }}</span><label-badge v-for="label in issue.labels" :key="label.id" :color="label.color" :text="label.text_color" :label="label.name" class="mr-1 mb-1" /></div>
                            <div class="d-sm-none"><span class="text-muted mr-2">#{{ issue.number }} {{ issue.state === 'open' && issue.comments.length === 0 ? 'opened' : '' }} {{ issue.state === 'open' && issue.comments.length > 0 ? 'updated' : '' }} {{ issue.state === 'closed' ? 'closed' : '' }} {{ issue.state === 'open' && issue.comments.length === 0 ? $datefns.fromNow(issue.created_at) : $datefns.fromNow(issue.updated_at) }}</span><label-badge v-for="label in issue.labels" :key="label.id" :color="label.color" :text="label.text_color" :label="label.name" class="mr-1 mb-1 d-none d-sm-inline-block" /></div>
                          </div>
                          <div class="col-sm-4 col-md-3 col-lg-2 text-right d-none d-sm-block">
                            <h5 class="box-title mb-0 text-uppercase"><span v-if="issue.state === 'closed'">{{ issue.state }}</span><span v-if="issue.comments.length > 0"><i class="fal fa-comments mx-2"></i>{{ issue.comments.length }}</span></h5>
                            <span v-if="issue.state === 'closed' || issue.comments.length > 0" class="text-muted">updated {{ $datefns.fromNow(issue.updated_at) }}</span>
                          </div>
                        </div>
                        <div v-else class="row">
                          <div class="col-12">
                            <label-badge v-for="label in issue.labels" :key="label.id" :color="label.color" :text="label.text_color" :label="label.name" class="mr-1 mb-1" />
                          </div>
                          <div class="col-sm-8 col-md-9" :class="boards.length < 4 ? 'col-lg-10' : ''">
                            <h5 class="box-title mb-0">{{ issue.title }}</h5>
                            <span class="text-muted mr-2">#{{ issue.number }} {{ issue.state === 'open' && issue.comments.length === 0 ? 'opened' : '' }} {{ issue.state === 'open' && issue.comments.length > 0 ? 'updated' : '' }} {{ issue.state === 'closed' ? 'closed' : '' }} {{ issue.state === 'open' && issue.comments.length === 0 ? $datefns.fromNow(issue.created_at) : $datefns.fromNow(issue.updated_at) }}</span>
                          </div>
                          <div v-if="issue.comments.length > 0" class="col-sm-4 col-md-3 text-right d-none d-sm-block" :class="boards.length < 4 ? 'col-lg-2' : ''">
                            <h5 class="box-title mb-0"><i class="fal fa-comments mr-2"></i>{{ issue.comments.length }}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>

            <div v-if="boards.length === 0" class="row">
              <div class="col-lg-12">
                <div class="widget-holder">
                  <div class="widget-bg">
                    <div class="widget-body">
                      <div class="text-center py-4">
                        <h4 class="my-0"><p><i class="fal fa-user-clock fa-5x text-stroke-5 text-icon-gray"></i></p><p class="mb-0">{{ $config.pretty_name }} is assigning a team to your project</p></h4>
                        <h6 class="fw-300">Please check back later</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="memberSettings.enable_members" :class="memberSettings.enable_files ? 'col-lg-6' : 'col-lg-12'">
                <div class="widget-holder">
                  <div class="widget-bg">
                    <div class="widget-body">
                      <div class="text-center py-4">
                        <h5 class="my-0"><p><i class="fal fa-users fa-5x text-stroke-4 text-icon-gray"></i></p><p>Invite additional members of your team</p></h5>
                        <Button :to="`/${project.group.path}/${project.path}/members`" type="primary" icon="fa-user-plus">Add Team Members</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="memberSettings.enable_files" :class="memberSettings.enable_members ? 'col-lg-6' : 'col-lg-12'">
                <div class="widget-holder">
                  <div class="widget-bg">
                    <div class="widget-body">
                      <div class="text-center py-4">
                        <h5 class="my-0"><p><i class="fal fa-code fa-5x text-stroke-4 text-icon-gray"></i></p><p>Upload project files or other data</p></h5>
                        <Button :to="`/${project.group.path}/${project.path}/files`" type="primary" icon="fa-cloud-upload">Upload Files</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <b-modal id="boardmodal" title="Board" centered v-if="$auth.profile.site_admin">
            <div class="form-group" v-if="!boardSettings.color">
              <label class="col-form-label">Name</label>
              <select class="form-control custom-select" v-model="boardSettings.id">
                <option></option>
                <option v-for="label in unusedLabels" :key="label.id" :value="label.id">{{ label.name }}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="col-form-label">Position</label>
              <input type="text" class="form-control" maxlength="2" v-model="boardSettings.position">
            </div>
            <template v-slot:modal-footer>
              <Button type="primary" class="mr-auto" @click="saveBoard()">Save Board</Button>
            </template>
          </b-modal>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { ModalPlugin, DropdownPlugin } from 'bootstrap-vue';
import Header from '@/components/AccountHeader.vue';
import Button from '@/components/Button.vue';
import LabelBadge from '@/components/LabelBadge.vue';
import toast from '@/modules/toast';

Vue.use(ModalPlugin);
Vue.use(DropdownPlugin);

export default {
  data() {
    return {
      project: {},
      labels: [],
      boards: [],
      issues: {},
      boardSettings: {
        id: '',
        position: 0,
      },
      memberSettings: {
        enable_files: false,
        enable_members: false,
      },
      issueUpdateTimer: undefined,
    };
  },
  computed: {
    unusedLabels() {
      const unused = [];
      for (let i = 0; i < this.labels.length; i += 1) {
        let found = false;
        for (let k = 0; k < this.boards.length; k += 1) {
          if (this.boards[k].id === this.labels[i].id) {
            found = true;
            break;
          }
        }
        if (!found) {
          unused.push(this.labels[i]);
        }
      }
      return unused;
    },
  },
  filters: {
    stripLabelScope(v) {
      if (v.indexOf('::') !== -1) {
        return v.substring(v.indexOf('::') + 2);
      }
      return v;
    },
  },
  methods: {
    editBoard(id) {
      this.boardSettings = JSON.parse(JSON.stringify(this.getBoard(id)));
      this.updateLabels();
      this.$bvModal.show('boardmodal');
    },
    saveBoard() {
      // position must be an int
      this.boardSettings.position = parseInt(this.boardSettings.position, 10);
      // id is the label identifier
      this.boardSettings.label = this.boardSettings.id;

      let resource;
      if (this.boardSettings.color) {
        resource = this.$api.put(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/boards/${this.boardSettings.id}`, this.boardSettings);
      } else {
        resource = this.$api.post(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/boards`, this.boardSettings);
      }
      resource
        .then(() => {
          this.updateBoards();
          this.$bvModal.hide('boardmodal');
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    deleteBoard(id) {
      if (!id) {
        return;
      }
      this.$api.delete(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/boards/${id}`)
        .then(() => {
          this.$cache.api.remove(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/boards`);
          for (let i = 0; i < this.boards.length; i += 1) {
            if (this.boards[i].id === id) {
              this.boards.splice(i, 1);
            }
          }
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    getBoard(id) {
      if (id) {
        for (let i = 0; i < this.boards.length; i += 1) {
          if (this.boards[i].id === id) {
            return this.boards[i];
          }
        }
      }
      return { id: undefined, position: 0 };
    },
    updateLabels() {
      this.$cache.api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/labels`)
        .then((res) => {
          this.labels = res.data;
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    updateIssues() {
      for (let i = 0; i < this.boards.length; i += 1) {
        this.$api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/issues?label=${this.boards[i].id}&order_by=updated_at&sort=desc`)
          .then((res) => {
            let issueDate = '';
            if (this.issues[this.boards[i].id] && this.issues[this.boards[i].id].length > 0) {
              issueDate = this.issues[this.boards[i].id][0].updated_at;
            }
            let resDate = '';
            if (res.data.length > 0) {
              resDate = res.data[0].updated_at;
            }
            if (issueDate !== resDate) {
              for (let k = res.data.length - 1; k >= 0; k -= 1) {
                // remove closed issues after 3 months
                const updatedAt = new Date(res.data[k].updated_at);
                if (res.data[k].state === 'closed' && this.$datefns.age(updatedAt, 'months') >= 3) {
                  res.data.splice(k, 1);
                } else {
                  // remove board label
                  for (let j = 0; j < res.data[k].labels.length; j += 1) {
                    if (res.data[k].labels[j].id === this.boards[i].id) {
                      res.data[k].labels.splice(j, 1);
                      break;
                    }
                  }
                }
              }
              // sort: move closed issues to end
              res.data.sort((a, b) => {
                if (a.state === 'closed' && b.state !== 'closed') {
                  return 1;
                }
                if (a.state !== 'closed' && b.state === 'closed') {
                  return -1;
                }
                return 0;
              });
              this.$set(this.issues, this.boards[i].id, res.data);
            }
          })
          .catch(() => {});
      }
    },
    updateBoards() {
      this.$cache.api.update.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/boards`)
        .then((res) => {
          this.boards = res.data;
          this.updateIssues();
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
  },
  async mounted() {
    try {
      this.project = (await this.$cache.api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}`)).data;
      console.log(this.project.boards_display_vertical);
      this.boards = (await this.$cache.api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/boards`)).data;
      if (this.boards.length === 0) {
        // set files, members project flags
        const m = (await this.$cache.api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/members`)).data;
        for (let i = 0; i < m.length; i += 1) {
          if (m[i].id === this.$auth.profile.id) {
            this.memberSettings.enable_files = m[i].enable_files;
            this.memberSettings.enable_members = m[i].enable_members;
            break;
          }
        }
      }
      this.updateIssues();
      this.issueUpdateTimer = setInterval(this.updateIssues, 10000);
      this.$store.commit('ready', true);
    } catch (e) {
      this.$store.commit('error', e);
    }
  },
  beforeDestroy() {
    clearInterval(this.issueUpdateTimer);
  },
  components: {
    Header,
    LabelBadge,
    Button,
  },
};
</script>
