<template>
  <router-link v-if="to" role="button" :to="to" :class="btnClasses"><i v-if="icon !== ''" class="fal mr-2" :class="icon"></i><slot></slot></router-link>
  <button v-else type="button" :class="btnClasses" @click="$emit('click')"><i v-if="icon !== ''" class="fal mr-2" :class="icon"></i><slot></slot></button>
</template>

<script>
export default {
  props: {
    to: String,
    type: String,
    icon: String,
  },
  computed: {
    btnClasses() {
      switch (this.type) {
        case 'default':
        case 'primary':
          return `btn btn-rounded btn-${this.type}`;
        default:
          return this.type;
      }
    },
  },
};
</script>
