<template>
  <span class="badge" v-b-tooltip.hover :title="tooltip">
    <span v-if="scope === ''" class="full" :style="`color: #${text}; background: #${color};`">{{ label }}</span>
    <span v-if="scope !== ''" class="scope" :style="`color: #${text}; background: #${color};`">{{ scope }}</span>
    <span v-if="scope !== ''" class="name" :style="`border-color: #${color}; color: #${color}`">{{ name }}</span>
  </span>
</template>

<script>
import Vue from 'vue';
import { TooltipPlugin } from 'bootstrap-vue';

Vue.use(TooltipPlugin);

export default {
  props: {
    text: String,
    color: String,
    label: String,
    tooltip: String,
  },
  computed: {
    scope() {
      if (this.label.indexOf('::') !== -1) {
        return this.label.substring(0, this.label.indexOf('::'));
      }
      return '';
    },
    name() {
      if (this.label.indexOf('::') !== -1) {
        return this.label.substring(this.label.indexOf('::') + 2);
      }
      return this.label;
    },
  },
};
</script>
